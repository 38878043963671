body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /*mt-4 text-sm leading-5 text-black w-full flex flex-col gap-5*/
}

.mail-content {
  margin-top: 1rem;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  color: rgb(0 0 0 / 1);
  width: 100%;
  display: flex;
  flex-direction: column;
  /*gap: 1.25rem !* 20px *!;*/
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border: 2px solid #f1f1f1;
}

.stable-scrollbar {
  scrollbar-gutter: stable !important;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.clean-textbox {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.circle-with-text {
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
}

.rs-picker-toolbar-right {
  background-color: var(--rs-btn-primary-hover-bg);
  border-radius: 0.4rem;
}
